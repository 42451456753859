import { useState } from "react";
import AxiosClient from "../Helper/axiosClient";

const useCommentHook = () => {
	const axios = AxiosClient();
	const [commentData, setCommentData] = useState(null);
	const [loadingCommentData, setLoading] = useState(false);

	const makeComment = async (_id, type, data) => {
		setLoading(true);
		axios.patch(`/${type}/comment/${_id}`, data).then(async (res) => {
			setCommentData(res.data.result);
			setLoading(false);
			window.toastr.success(res.data.message);
		}).catch(error => {
			// handle error
			console.log(error);
			window.toastr.error(error?.response ? error.response.data.message : error.message);
			setLoading(false);
		});
	}

	return { makeComment, commentData, loadingCommentData, setCommentData };
}

export default useCommentHook;