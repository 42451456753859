import { useLocation } from "react-router-dom";
import IconSVG from "../../../Utils/svg";
import { useAuthUser } from "react-auth-kit";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Grid, TextField } from "@mui/material";
import { Edit, Expand, Upload, UploadRounded } from "@mui/icons-material";
import useUpdateProject from "../../../Hooks/useupdateproject";
import useUpdateProjectImageCaption from "../../../Hooks/use_update_project_image_caption";
import CustomModal from "../modal/customModal";

const Media = ({ project, hostUrl }) => {
	const location = useLocation();
	const userData = useAuthUser()();
	const [projectToDisplay, setProjectToDisplay] = useState(project);
	const [imageText, setImageText] = useState(null);
	const [action, setAction] = useState('');
	const [imageData, setImageData] = useState({ index: 0, caption: '', path: '' });
	const { data: updatedProject, loading, upDAteProject } = useUpdateProject();
	const { loading_project_image_caption_data, projectImageCaption_data, uploadProjectImageCaption } = useUpdateProjectImageCaption();
	const [openUploadProjectImageModal, setOpenUploadProjectImageModal] = useState(false);
	const [openImageWideModal, setOpenImageWideModal] = useState(false);
	const [openAddCaptionModal, setOpenAddCaptionModal] = useState(false);
	const isAdminPage = ((userData?.role?.toLowerCase() === "admin") && (location.pathname.search("/spp") === 0));

	// function
	const handleInputChange = e => {
		let data = e.target;

		const { value } = data;

		setImageData(prev => {
			return {
				...prev,
				caption: value
			}
		});
	}

	async function handleAddCaptionToImage(data) {
		console.log("adding caption", data);

		if (!data.project || !data.caption || data.caption === '') return window.toastr.error("Empty Input");

		await uploadProjectImageCaption(data.project, imageData);
		setAction("projectImageCaption_data");
	}

	async function handleUploadProject(data) {
		console.log("upload image", data);

		const formData = new FormData();

		const file = document.getElementById("file");

		Object.values(file.files).forEach((f, index) => formData.append("images", file.files[index]));

		// submit project
		if (file.files.length) await upDAteProject(data.project, formData);
		else return window.toastr.error("Add an image");
		setAction("updatedProject");
	}

	useEffect(() => {
		if (updatedProject && action === "updatedProject") {
			setImageText(null);
			setProjectToDisplay(updatedProject);
			setOpenUploadProjectImageModal(false);
		}

		if (projectImageCaption_data && action === "projectImageCaption_data") {
			setOpenAddCaptionModal(false);
			setProjectToDisplay(projectImageCaption_data);
			setImageData({ index: 0, caption: '', path: '' });
		}

		console.log("Rendering...");
	}, [updatedProject?.images, projectImageCaption_data]);

	return (
		<>
			<div className="flex-grow flex flex-col h-full" data-testid="project-tab_media_content">
				<div className="projectPage_media-navbar__gDfss">
					<div className="projectPage_media-nav__Ij9ni projectPage_media-nav-active__7QEl_">
						<p>All Pictures</p>
						<div className="projectPage_active-icon__x8GtR" />
					</div>
				</div>

				<div className=" projectPage_gallery___fDWv">
					{
						(project.images === undefined) ?
							(
								<div className='flex h-full flex-col items-center justify-center'>
									<div className=' mx-auto'>
										<img src={IconSVG.no_photo} alt='window wipe' className='w-96' />
									</div>
									<p className="mt-5 medium text-center text-2xl">No image yet</p>
									<p className="text-sm text-center text-input-border mt-3 w-10/12 lg:w-7/12 mx-auto">It seems that no one has updated or reviewed this project with pictures. Why not upload some with your review.</p>
									<div className="bg-white cursor-pointer text-primary hover:bg-primary hover:text-white transition ease-in-out duration-300 rounded-md px-4 py-1 mt-6">Back To Overview</div>
								</div>

							) :
							(projectToDisplay.images).map((image, index) => {
								return (
									<div key={index} className="w-full md:w-[48%] lg:w-[32.333333%] m-1">
										<div key={image.path} className="projectPage_gallery__item__xPnf7 overflow-visible w-full" style={{ cursor: "default" }}>
											<div className="projectPage_image__nKqqh">
												<div className="text-white p-2" >
													<img alt={project.name} src={`${hostUrl}${image.path}`} style={{ position: 'absolute', inset: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%', objectFit: 'cover' }} sizes="100vw" />
													<ButtonGroup variant="outlined" size="small" color="inherit" aria-label="Action buttons" className="relative z-[1]">
														<Button onClick={() => {
															setOpenImageWideModal(true);
															setImageData({
																index,
																path: image.path,
																caption: image.caption
															});
														}}><Expand /></Button>
														{
															isAdminPage &&
															<Button onClick={() => {
															setOpenAddCaptionModal(true);
															setImageData({
																index,
																path: image.path,
																caption: image.caption
															});
														}}><Edit /></Button>
														}
													</ButtonGroup>
													<noscript />
												</div>
											</div>
										</div>
										<div className="projectPage_project-location-card__f7FjG">
											<div className="flex items-center text-xs space-x-1">
												<p className="text-dark-grey medium">{image.caption}</p>
											</div>
											<div className="flex items-center justify-between mt-4 text-2-xs">
												{/* <p className="uppercase medium text-input-border">2024-09-23</p> */}
											</div>
										</div>
									</div>
								)
							})
					}
				</div>

				{/* View Image */}
				<CustomModal title={project.name} confirm={
					{
						cancelText: "Close"
					}}
					open={{
						init: openImageWideModal,
						set: setOpenImageWideModal
					}}>
					<Grid container alignContent="center" justifyContent="center" spacing={1}>
						<Grid item xs={12}>
							<div className="projectPage_image__nKqqh">
								<div className="text-white p-2" >
									<img className="" alt={project.name} src={`${hostUrl}${imageData.path}`} />
								</div>
							</div>
						</Grid>
					</Grid>
				</CustomModal>

				{/* Add caption */}
				<CustomModal title="Add a caption to this image" confirm={
					{
						confirmText: "Update",
						cancelText: "Close",
						isLoading: loading_project_image_caption_data,
						handleConfirm: handleAddCaptionToImage,
						parameter: { project: project._id, ...imageData }
					}}
					open={{
						init: openAddCaptionModal,
						set: setOpenAddCaptionModal
					}}>
					<Grid container alignContent="center" justifyContent="center" spacing={1}>
						<Grid item xs={12}>
							<div className="projectPage_image__nKqqh">
								<div className="text-white p-2" >
									<img className="" alt={project.name} src={`${hostUrl}${imageData.path}`} />
								</div>
							</div>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								multiline
								type="text"
								name="Caption"
								value={imageData.caption || ''}
								onChange={handleInputChange}
								placeholder="Describe this image"
								label="Caption" />
						</Grid>
					</Grid>
				</CustomModal>

				{/* Upload images */}
				<CustomModal title="Add an image to this project" confirm={
					{
						confirmText: "Upload",
						cancelText: "Close",
						isLoading: loading,
						handleConfirm: handleUploadProject,
						parameter: { project: project._id }
					}}
					open={
						{
							init: openUploadProjectImageModal,
							set: setOpenUploadProjectImageModal
						}
					}>
					<Grid container alignContent="center" justifyContent="center" spacing={1}>

						<Grid item xs={12} md={6} lg={4}>
							<input
								accept="image/*"
								className=""
								style={{ display: 'none' }}
								id="file"
								multiple
								type="file"
								onChange={(e) => setImageText(e.target.files.length)} />
							<label htmlFor="file">
								<button
									style={{
										borderStyle: "dashed",
										borderRadius: 0
									}}
									data-testid="login-button"
									type="button"
									onClick={(e) => e.currentTarget.parentElement.click()}
									className="bg-transparent border-2 border-[#007bff]  h-40 my-[3em] text-primary w-full rounded">
									<p className="medium">
										{imageText ? `Uploading ${imageText} Image(s)` : "Upload Images"}
									</p>
								</button>
							</label>
						</Grid>
					</Grid>
				</CustomModal>

				{
					isAdminPage &&
					<div className="text-center	my-5">
						<Button variant="contained" startIcon={<UploadRounded />} onClick={() => setOpenUploadProjectImageModal(true)}>Upload Image</Button>
					</div>
				}
			</div>
		</>
	)
}

export default Media;