import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function BasicTabs(props) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		console.log('new value', newValue);
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					{
						props.tabsTitle.map((theTabTitle, index) => <Tab key={index} label={theTabTitle} {...a11yProps(index)} />)
					}
				</Tabs>
			</Box>
			{
				props.tabsBody.map((theTabBody, index) => <CustomTabPanel key={index} value={value} index={index}>
					{theTabBody}
				</CustomTabPanel>)
			}
		</Box>
	);
}
