// Import Dependency
import { useEffect, useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import useCommentHook from "../../../Hooks/useCommentHook";
import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useAuthUser } from "react-auth-kit";

// Import Components
const CommentModal = () => {
	const userData = useAuthUser()();
	const [openCommentModal, setOpenCommentModal] = useState(false)
	const { makeComment, loadingCommentData, commentData, setCommentData } = useCommentHook();
	const [commentInputData, setCommentInputData] = useState({
		description: "",
		radioValue: "",
		name: "",
		userId: userData?._id || null
	});
	const nameRef = useRef()
	const reviewRef = useRef(null)
	const cursorPositionRef = useRef(0);

	const milestoneText = {
		preliminaries_sum: "Preliminary Sum",
		provisional_sums: "Provisional Sum",
		measured_work: "Measured Work"
	}

	const CommentPopUp = ({ projectOrMilestone, onProject = true }) => {
		// handle cancel delete function
		const HandleDeleteCancel = () => {
			setOpenCommentModal(false)
		}

		const handleRadioSelection = (event) => {
			setCommentInputData((prev) => ({ ...prev, radioValue: event.target.value }));
		};

		const handleSubmit = async (e) => {
			e.preventDefault();

			const commentOn = onProject ? "project" : "milestone";

			await makeComment(projectOrMilestone._id, commentOn, commentInputData);
		}

		useEffect(() => {
			if (commentData) {
				setCommentInputData({
					description: "",
					radioValue: "",
					name: "",
				});
				setOpenCommentModal(false);
				setCommentData(null);
			}

			console.log("Rendering... c", openCommentModal);
		}, [commentData]);

		useEffect(() => {
			if (reviewRef.current === document.activeElement) {
				reviewRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
			}
		});

		const handleReviewChange = (event) => {
			// Store the cursor position before updating the value
			const { selectionStart, selectionEnd } = event.target;
			cursorPositionRef.current = selectionStart;

			// setValue(event.target.value);
			setCommentInputData((prev) => ({ ...prev, description: event.target.value }))
		};

		return (
			<div onClick={HandleDeleteCancel} className='fixed p-2 md:p-5 top-0 left-0 w-full h-full bg-black/[.3] flex justify-center items-center z-[1000] '>
				<div
					className='bg-white w-full md:w-5/6 lg:w-3/6 rounded-lg max-h-[90%] overflow-scroll client-overflow-scroll'
				onClick={(e) => e.stopPropagation()}>
					<div className='text-center p-4'>
						<div className="flex justify-between items-center">
							<div className="flex items-center space-x-3">
								{console.log("comment", projectOrMilestone)}
								<h4 className="text-xs medium truncate">Make a comment: {projectOrMilestone.name || milestoneText[projectOrMilestone.typeOf]}</h4>
							</div>
							<IconButton aria-label="close" onClick={() => setOpenCommentModal(false)}>
								<Close />
							</IconButton>
						</div>
						<form key='form1' onSubmit={handleSubmit} id='myform' className="block">
							<div className="relative flex mt-5">
								<p className="text-3-xs text-input-border absolute left-3 top-2">Enter Name</p>
								<input
									ref={nameRef}
									key='name'
									autoFocus={nameRef.current === document.activeElement}
									value={commentInputData.name}
									onChange={(e) => setCommentInputData((prev) => ({ ...prev, name: e.target.value }))}
									className="projectPage_text-input__cGTC_ focus:border-accepted w-full"
									required
								/>
							</div>
							<div className="relative mt-3">
								<p className="text-3-xs text-input-border absolute left-3 top-2">What’s the summary of your review?</p>
								<p className="text-3-xs text-accepted absolute right-3 top-2">Required</p>
								<textarea
									key='review'
									ref={reviewRef}
									autoFocus={reviewRef.current === document.activeElement}
									value={commentInputData.description}
									onChange={(e) => handleReviewChange(e)}
									className="w-full h-32 projectPage_text-area__carzK focus:border-accepted"
									required
								/>
							</div>
							<div>
								<div className="flex justify-start items-center form-check">
									<input
										className="mr-2"
										type="radio"
										name="radioSelection"
										onChange={(e) => handleRadioSelection(e)}
										id="exampleRadios1"
										checked={(commentInputData.radioValue).toLowerCase() === 'Poor'.toLowerCase()}
										value="Poor"
										required
									/>
									<label className="text-xs" htmlFor="exampleRadios1">
										Poor
									</label>
								</div>
								<div className="flex justify-start items-center form-check">
									<input
										className="mr-2"
										type="radio"
										name="radioSelection"
										onChange={(e) => handleRadioSelection(e)}
										checked={(commentInputData.radioValue).toLowerCase() === 'Fair'.toLowerCase()}
										id="exampleRadios2"
										value="Fair"
										required
									/>
									<label className="text-xs" htmlFor="exampleRadios2">
										Fair
									</label>
								</div>
								<div className="flex justify-start items-center form-check">
									<input
										className="mr-2"
										type="radio"
										name="radioSelection"
										onChange={(e) => handleRadioSelection(e)}
										checked={(commentInputData.radioValue).toLowerCase() === 'Good'.toLowerCase()}
										id="exampleRadios3"
										value="Good"
										required
									/>
									<label className="text-xs" htmlFor="exampleRadios3">
										Good
									</label>
								</div>
								<div className="flex justify-start items-center form-check">
									<input
										className="mr-2"
										type="radio"
										name="radioSelection"
										onChange={(e) => handleRadioSelection(e)}
										checked={(commentInputData.radioValue).toLowerCase() === 'Better'.toLowerCase()}
										id="exampleRadios4"
										value="Better"
										required
									/>
									<label className="text-xs" htmlFor="exampleRadios4">
										Better
									</label>
								</div>
								<div className="flex justify-start items-center form-check">
									<input
										className="mr-2"
										type="radio"
										name="radioSelection"
										onChange={(e) => handleRadioSelection(e)}
										checked={(commentInputData.radioValue).toLowerCase() === 'Perfect'.toLowerCase()}
										id="exampleRadios5"
										value="Perfect"
										required
									/>
									<label className="text-xs" htmlFor="exampleRadios5">
										Perfect
									</label>
								</div>
							</div>
						</form>

					</div>
					{/* { confirmButtonText && 
                <div className='flex justify-between items-center'>
                  {cancelButton && <button onClick={HandleDeleteCancel} className="py-2 px-3 md:px-4 bg-transparent border-1 border-primary dark:border-hovericon text-primary dark:text-hovericon rounded-full font-semibold flex items-center w-fit text-xs"> 
                      {cancelButton}
                      <div className='w-3 h-3 stroke-primary dark:stroke-hovericon ml-2'>
                        <Icon cancel='cancel' />
                      </div>
                  </button>}
                  <button onClick={() => confirmButtonTextAction()} className="py-2 px-3 md:px-4 bg-primary text-white  border-1 border-primary rounded-full font-semibold flex items-center ml-auto w-fit text-xs"> 
                   {confirmButtonText} 
                  </button>
                </div>
            } */}
					<div className="px-8 py-3 text-sm flex space-x-3 justify-end bg-primary">
						<button onClick={() => HandleDeleteCancel()} className="py-2 px-5 hover:bg-sky-700 rounded-full text-white medium">
							<p>Discard</p>
						</button>
						<button form="myform" className="py-2 px-5 rounded-full medium text-white bg-features-brown">
							{loadingCommentData ? <CircularProgress size={10} sx={{ color: 'white' }} /> : <p>Post</p>}
						</button>
					</div>

				</div>

			</div>
		)
	}
	return { openCommentModal, setOpenCommentModal, CommentPopUp, commentData }
}

export default CommentModal;