import { useState } from "react";
import AxiosClient from "../Helper/axiosClient";

const useRegisterMilestone = () => {
	const axios = AxiosClient();
	const [registerdMilestoneData, setData] = useState(null);
	const [isRegisterMilestoneLoading, setLoading] = useState(false);

	const registerMilestone = async (data) => {
		setLoading(true);
		axios.post("/milestone/register", data, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		}).then(async (res) => {
			setData(res.data.result);
			setLoading(false);
			window.toastr.success(res.data.message);
		}).catch(error => {
			// handle error
			console.log(error);
			window.toastr.error(error?.response ? error.response.data.message : error.message);
		});
	}

	return { registerMilestone, registerdMilestoneData, isRegisterMilestoneLoading }
}

export default useRegisterMilestone;