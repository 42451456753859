import "../../css/projectDetails/activity.css";
import { Button, ButtonGroup, Grid, TextField } from "@mui/material";
import SiteImages from "../../../Utils/images";
import useProjectMilestone from '../../../Hooks/useProjectMilestone';
import { Fragment, useEffect, useState } from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import moment from 'moment';
import organiseMilestone from "../../functions/organiseMilestone";
import { Comment, Done, Edit, Expand, Add, PreviewOutlined } from "@mui/icons-material";
import CustomModal from "../modal/customModal";
import useUpdateMilestone from "../../../Hooks/useUpdateMilestone";
import { useAuthUser } from "react-auth-kit";
import { useLocation } from "react-router-dom";
import MilestoneInput from "../../administrator/project/functions/milestoneInput";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import canMilestoneBeCompleted from "./functions/canMilestoneBeCompleted";
import useUpdateProject from "../../../Hooks/useupdateproject";
import useUpdateMilestoneImageCaption from "../../../Hooks/use_update_milestone_image_caption";
import CommentModal from "../modal/commentmodal";
import Review from "./review";
import BasicTabs from "../muiComponent/Tab";
import useRegisterMilestone from "../../../Hooks/useRegisterMilestone";

const Activity = ({ project, hostUrl }) => {
	const location = useLocation();
	const userData = useAuthUser()();
	const { pMilestone, fetchProjectMilestone } = useProjectMilestone();
	const [action, setAction] = useState('');
	const [imageText, setImageText] = useState(null);
	const organiseMilestones = organiseMilestone(pMilestone);
	const { openCommentModal, setOpenCommentModal, CommentPopUp, commentData } = CommentModal();
	const [openMarkMilestoneAsCompletedModal, setOpenMarkMilestoneAsCompletedModal] = useState(false);
	const [imageData, setImageData] = useState({ index: 0, caption: '', path: '' });
	const [commentItem, setCommentItem] = useState();
	const [openUpdateMilestoneModal, setOpenUpdateMilestoneModal] = useState(false);
	const [openImageWideModal, setOpenImageWideModal] = useState(false);
	const { isRegisterMilestoneLoading, registerMilestone, registerdMilestoneData } = useRegisterMilestone();
	const [isNewMilestone, setIsNewMilestone] = useState(false);
	const [openAddCaptionModal, setOpenAddCaptionModal] = useState(false);
	const { loading_milestone_image_caption_data, milestoneImageCaption_data, uploadMilestoneImageCaption } = useUpdateMilestoneImageCaption();
	const isAdminPage = ((userData?.role?.toLowerCase() === "admin") && (location.pathname.search("/spp") === 0));
	const { isUpdatingMilestoneLoading, updateMilestone, updatedMilestone } = useUpdateMilestone();
	const { upDAteProject } = useUpdateProject();
	canMilestoneBeCompleted(organiseMilestones());
	const initRegisterMilestoneInput = {
		project: project._id,
		typeOf: "preliminaries_sum",
		level: 0,
		duration: null,
		unit: 0,
		amount: 0,
		quantity: 0,
		description: '',
		rate: 0
	}
	const initialInput = {
		funding: { name: "funding", focus: () => { }, value: '' },
		change: { name: "change", focus: () => { }, value: '' },
		nextFunding: { name: "nextFunding", focus: () => { }, value: '' }
	}
	const [milestoneModalDetails, setMilestoneModalDetails] = useState(initRegisterMilestoneInput);
	const [inputDetails, setInputDetails] = useState(initialInput);
	const milestoneText = {
		preliminaries_sum: "Preliminary Sum",
		provisional_sums: "Provisional Sum",
		measured_work: "Measured Work"
	}

	// to store the edited milestione details
	// const [milestoneModalDetails, setOpenMilestoneModalDetails] = useState({ typeOf: "preliminaries_sum", level: 0, rate: 0, amount: 0, duration: null, description: '', quantity: 0 });

	const displayComment = item => {
		setCommentItem(item);
		setOpenCommentModal(true);
		return item;
	}

	// function
	const handleInputChange = (e, dateName) => {
		let data = e.target;

		if (!data) data = { name: dateName, value: e, focus: null };

		let { name, value, focus } = data;

		let innerHtml = '';

		if (e?.target?.TagName === "SELECT") innerHtml = e?.target?.selectedOptions[0]?.innerHTML;

		setInputDetails(prev => {
			return {
				...prev,
				[name]: { name, focus, value, innerHtml }
			}
		});
	}

	const handleImageInputChange = e => {
		let data = e.target;

		const { value } = data;

		setImageData(prev => {
			return {
				...prev,
				caption: value
			}
		});
	}

	// function
	const handelMilestoneChange = (e, index, dateName) => {
		let data = e?.target;

		if (!data) data = { name: dateName, value: e };

		let { name, value } = data;

		setMilestoneModalDetails(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const handelMarkMilestoneAsCompleted = (data) => {
		// update current milestione
		updateMilestone(data._id, { status: "completed", completed: data.completed, funding: data.funding.value, change: data.change.value });
		// // update next milestone
		if (data.next) updateMilestone(data.next, { funding: data.nextFunding.value, status: "progress" });
		// update project
		if (data.nextFunding.value === '') upDAteProject(project._id, { status: "WAITING PAY" });
	}

	async function handleAddCaptionToImage(data) {
		console.log("adding caption", data);

		if (!data.milestone || !data.caption || data.caption === '') return window.toastr.error("Empty Input");

		await uploadMilestoneImageCaption(data.milestone, imageData);
		setAction("milestoneImageCaption_data");
	}

	const handleUpdateMilestone = (data) => {
		const formData = new FormData();

		console.log("milestoneModalDetails", milestoneModalDetails);
		const file = document.getElementById("file");

		Object.keys(milestoneModalDetails).forEach(key => formData.append(key, milestoneModalDetails[key]));
		Object.values(file.files).forEach((f, index) => formData.append("images", file.files[index]));

		isNewMilestone ? registerMilestone(formData) : updateMilestone(milestoneModalDetails._id, formData);
	}

	useEffect(() => {
		if (project._id) {
			fetchProjectMilestone(project._id);
		}

		if (milestoneImageCaption_data && action === "milestoneImageCaption_data") {
			setOpenAddCaptionModal(false);
			// setProjectToDisplay(projectImageCaption_data);
			setImageData({ index: 0, caption: '', path: '' });
		}

		setOpenMarkMilestoneAsCompletedModal(false);
		setOpenUpdateMilestoneModal(false);
		setImageText(null);

		console.log("Rerending...l");
	}, [project._id, updatedMilestone, milestoneImageCaption_data, commentData, registerdMilestoneData]);

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<div className="w-full lg:w-9/12">
				<div className="">
					<p className="text-2-xs text-light-grey-2"></p>
					<div className="mt-1">
						<div className="flex items-center space-x-2 flex-shrink-0 mt-1 cursor-pointer ml-5">
							<div className="h-6 w-6 sm:h-10 sm:w-10 rounded-full object-cover">
								<img alt="Nigerian Railway Mordernization (Idu to Kaduna)" width="100" height="100" decoding="async" data-nimg="1" className="h-6 w-6 sm:h-10 sm:w-10 rounded-full object-cover" style={{ color: 'transparent', backgroundSize: 'cover', backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat', backgroundImage: 'url()' }} src={SiteImages.federal} />
							</div>
							<div>
								<p className="text-2-xs uppercase text-input-border">{project.status}</p>
								<p className="text-sm lg:text-lg medium" data-testid="project-display_name">Milestones to be achived</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			{
				organiseMilestones().length ?
					organiseMilestones().map((orgMs, i) => orgMs.map((milestone, index, arr) => {
						return (
							<Fragment key={index}>
								<Timeline key={index} position="alternate" sx={{
									[`& .${timelineOppositeContentClasses.root}`]: {
										flex: 0.1,
									},
								}}>
									<TimelineItem>
										<TimelineOppositeContent color="text.secondary">
											<p className="text-[8px] md:text-xs">{moment(milestone.start_date).format("MMM Do YYYY")}</p>
											{
												((isAdminPage && milestone.showButton && !milestone.completed)) &&
												<Button color="success" size="small" sx={{ minWidth: "auto" }} onClick={() => {
													setOpenMarkMilestoneAsCompletedModal(true);
													setMilestoneModalDetails(milestone);
												}} title="Mark as completed"><Done /></Button>
											}
										</TimelineOppositeContent>
										<TimelineSeparator>
											<TimelineDot sx={[(milestone.completed && { background: "#007bff" })]} />
											<TimelineConnector sx={[(milestone.completed && { background: "#007bff" })]} />
										</TimelineSeparator>
										<TimelineContent>
											<div className="bg-white rounded-lg p-2 sm:p-4 md:p-6 w-full mx-auto position-relative editContainer" id="projectReviewNoReference">
												<ButtonGroup variant="outlined" size="small" color="inherit" sx={{ position: "absolute", top: "1em", right: "1em" }} aria-label="Action buttons" className="editBtn relative z-[1]">
													{
														isAdminPage &&
														<Button onClick={() => {
															setImageText(null);
															setIsNewMilestone(false);
															setMilestoneModalDetails({ ...milestone, duration: moment(milestone.duration) });
															setOpenUpdateMilestoneModal(true);
														}} variant="contained" color="error" size="small"><Edit /></Button>
													}
													<Button variant="contained" color="primary" size="small" title="Comment on this project" onClick={() => displayComment(milestone)}>
														<Comment />
													</Button>
												</ButtonGroup>
												<div>
													<div className="projectPage_author-details-group__DMKJG">
														<Grid container spacing={1} className="projectPage_profile-and-date__tQ8jG">
															<Grid item xs>
																<div className="flex items-center space-x-2">
																	<p className="projectPage_posted__Sj_3G flex">
																		<span className="mr-1">MILESTONE</span> {milestone.level + 1}
																	</p>
																	<h4 className="projectPage_review-author-name__C_D_Y flex">
																		<span className="text-black font-filson-bold hover:underline cursor-pointer">{milestoneText[milestone.typeOf]}</span>
																	</h4>
																</div>
																<div className="projectPage_update-detail__O0bzg">
																	<p className="projectPage_posted__Sj_3G flex">
																		<span className="mr-1">End Date:</span> {moment(milestone.duration).format("MMM Do YYYY")}
																	</p>
																</div>
																<div className="projectPage_update-detail__O0bzg">
																	<p className="projectPage_posted__Sj_3G flex text-[10px] md:text-xs">
																		<span className="mr-1">Due Date:</span>
																		{moment().to(moment(milestone.duration))}
																	</p>
																</div>
															</Grid>
															<Grid item marginLeft={0} xs>
																<div className="flex items-center space-x-2">
																	<p className="projectPage_posted__Sj_3G flex">
																		<span className="mr-1">Budget</span> NGN {milestone.amount}
																	</p>
																</div>
																<div className="projectPage_update-detail__O0bzg">
																	<p className="projectPage_posted__Sj_3G flex">
																		<span className="mr-1">Quantity:</span> {milestone.quantity}
																	</p>
																</div>
																<div className="projectPage_update-detail__O0bzg">
																	<p className="projectPage_posted__Sj_3G flex">
																		<span className="mr-1">Rate:</span> {milestone.rate}
																	</p>
																</div>
															</Grid>
														</Grid>
													</div>
													<div className="mt-3">
														<p className="projectPage_post-title__ZttU3 text-[10px] md:text-xs">Status: {milestone.status}</p>
														<p className="mt-2 text-[10px] md:text-xs">{milestone.description}</p>
													</div>
													<BasicTabs tabsTitle={["Media", "Reports"]} tabsBody={[
														// tab 1
														<div className="flex flex-wrap gap-2">
															{
																milestone.images.length ?
																	milestone.images.map((image, index) =>
																		<div key={index} className="space-x-3 overflow-x-auto mt-3 client-overflow-scroll sm:w-[33.333333%]">
																			<div className="projectPage_image-container__J5qdk" style={{ cursor: "default" }}>
																				<div className="projectPage_post-image__KWgTS" style={{ color: "white" }}>
																					<img alt="milestone pic" src={`${hostUrl}${image.path}`} className="object-cover w-full h-full" decoding="async" data-nimg="fill" />
																					<ButtonGroup variant="outlined" size="small" color="inherit" aria-label="Action buttons" className="top-2 absolute left-0 z-10">
																						<Button onClick={() => {
																							setOpenImageWideModal(true);
																							setImageData({
																								index,
																								path: image.path,
																								caption: image.caption
																							});
																						}}><Expand /></Button>
																						{
																							isAdminPage &&
																							<Button onClick={() => {
																								setOpenAddCaptionModal(true);
																								setMilestoneModalDetails(milestone);
																								setImageData({
																									index,
																									path: image.path,
																									caption: image.caption
																								});
																							}}><Edit /></Button>
																						}
																					</ButtonGroup>
																				</div>
																			</div>
																			<div className="projectPage_project-location-card__f7FjG">
																				<div className="flex items-center text-xs space-x-1">
																					<p className="text-dark-grey medium">{image.caption}</p>
																				</div>
																				<div className="flex items-center justify-between mt-4 text-2-xs">
																					{/* <p className="uppercase medium text-input-border">2024-09-23</p> */}
																				</div>
																			</div>
																		</div>
																	) : <div className="">No Media</div>
															}
														</div>,
														// tab 2
														<div className="">
															<Review project={milestone} setOpenCommentModal={setOpenCommentModal} />
														</div>
													]} />
												</div>
											</div>

										</TimelineContent>
									</TimelineItem>
								</Timeline>
							</Fragment>
						)
					})) :
					(
						<div className='flex h-full flex-col items-center justify-center'>
							<div className=' mx-auto'>
								<img src={SiteImages.developer} alt='window wipe' className='w-96' />
							</div>
							<p className="mt-5 medium text-center text-2xl">Sorry! We don't have any updates available</p>
							<p className="text-sm text-center text-input-border mt-3 w-10/12 lg:w-7/12 mx-auto">Unfortunately, we have not posted any update on this project. Kindly DELTA State PROJECT MONITORING APP (DPMA) and check back with us in the near future.</p>
							<div className="bg-white cursor-pointer text-primary hover:bg-primary hover:text-white transition ease-in-out duration-300 rounded-md px-4 py-1 mt-6">Back To Overview</div>
						</div>

					)

			}

			{
				isAdminPage && <div className='flex flex-col items-center justify-center py-10'>
					<Button variant="contained" startIcon={<Add />} onClick={() => {
						setImageText(null);
						setIsNewMilestone(true);
						setMilestoneModalDetails(initRegisterMilestoneInput, pMilestone);
						setOpenUpdateMilestoneModal(true);
					}}>Milestone</Button>
				</div>
			}

			{/* Comment */}
			{
				openCommentModal &&
				(commentItem && <div><CommentPopUp projectOrMilestone={commentItem} onProject={false} /> </div>)
			}

			{/* View Image */}
			<CustomModal title={project.name} confirm={
				{
					cancelText: "Close"
				}}
				open={{
					init: openImageWideModal,
					set: setOpenImageWideModal
				}}>
				<Grid container alignContent="center" justifyContent="center" spacing={1}>
					<Grid item xs={12}>
						<div className="projectPage_image__nKqqh">
							<div className="text-white p-2" >
								<img className="" alt={project.name} src={`${hostUrl}${imageData.path}`} />
							</div>
						</div>
					</Grid>
				</Grid>
			</CustomModal>

			{/* Add caption */}
			<CustomModal title="Add a caption to this image" confirm={
				{
					confirmText: "Update",
					cancelText: "Close",
					isLoading: loading_milestone_image_caption_data,
					handleConfirm: handleAddCaptionToImage,
					parameter: { milestone: milestoneModalDetails._id, ...imageData }
				}}
				open={{
					init: openAddCaptionModal,
					set: setOpenAddCaptionModal
				}}>
				<Grid container alignContent="center" justifyContent="center" spacing={1}>
					<Grid item xs={12}>
						<div className="projectPage_image__nKqqh">
							<div className="text-white p-2" >
								<img className="" alt={project.name} src={`${hostUrl}${imageData.path}`} />
							</div>
						</div>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							multiline
							type="text"
							name="Caption"
							value={imageData.caption || ''}
							onChange={handleImageInputChange}
							placeholder="Describe this image"
							label="Caption" />
					</Grid>
				</Grid>
			</CustomModal>

			{/* mark milestone as completed */}
			<CustomModal
				title="Mark this milestone as completed"
				confirm={{
					confirmText: "Confirm",
					cancelText: "Close",
					isLoading: isUpdatingMilestoneLoading,
					handleConfirm: handelMarkMilestoneAsCompleted,
					parameter: {
						_id: milestoneModalDetails?._id,
						completed: true, ...inputDetails,
						next: milestoneModalDetails?.next
					}
				}}
				open={{
					init: openMarkMilestoneAsCompletedModal,
					set: setOpenMarkMilestoneAsCompletedModal
				}}>
				<div className="flex items-center space-x-2">
					<p className="projectPage_posted__Sj_3G flex">
						<span className="mr-1">MILESTONE</span> {milestoneModalDetails.level + 1}
					</p>
					<h4 className="projectPage_review-author-name__C_D_Y flex">
						<span className="text-black font-filson-bold hover:underline cursor-pointer">{milestoneText[milestoneModalDetails.typeOf]}</span>
					</h4>
				</div>
				<div className="items-center space-x-2">
					<Grid container spacing={1}>
						<Grid item xs>
							<p className="text-xs medium truncate">Did you receive pay for this milestione?</p>
							<TextField
								fullWidth type="number"
								name={inputDetails.funding.name}
								value={inputDetails.funding.value}
								onChange={handleInputChange}
								label="How much"
							/>
						</Grid>
						<Grid item xs>
							<p className="text-xs medium truncate">Have you receive pay for the next milestione?</p>
							<TextField
								fullWidth type="number"
								name={inputDetails.nextFunding.name}
								value={inputDetails.nextFunding.value}
								onChange={handleInputChange}
								label="How much"
							/>
						</Grid>
						<Grid item xs>
							<p className="text-xs medium truncate">How much is left after milestione completion?</p>
							<TextField
								fullWidth type="number"
								name={inputDetails.change.name}
								value={inputDetails.change.value}
								onChange={handleInputChange}
								label="How much is the balance?"
							/>
						</Grid>
					</Grid>
				</div>
				<p className="text-[#CF5F56]">Clicking "Confirm" will confirm that the above milestone has been completed!</p>
			</CustomModal>

			{/* Edit milestione */}
			<CustomModal
				title={isNewMilestone ? "Add a Milestone" : "Edit the details of this milestone"}
				confirm={{
					confirmText: isNewMilestone ? "Add" : "Update",
					cancelText: "Close",
					isLoading: isNewMilestone ? isRegisterMilestoneLoading : isUpdatingMilestoneLoading,
					handleConfirm: handleUpdateMilestone,
					parameter: milestoneModalDetails
				}}
				open={{
					init: openUpdateMilestoneModal,
					set: setOpenUpdateMilestoneModal
				}}>
				<MilestoneInput
					milestoneText={milestoneText}
					milestone={milestoneModalDetails}
					handelMilestoneChange={handelMilestoneChange}
					imageText={imageText}
					setImageText={setImageText} />
			</CustomModal>
		</LocalizationProvider>
	)
}

export default Activity
