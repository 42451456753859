import { Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export default function MilestoneInput({ milestoneText, milestone, handelMilestoneChange, imageText, setImageText }) {
	return <div className="m-0 p-0">
		<div className="my-2">
			<TextField
				fullWidth
				select
				defaultValue={milestoneText.preliminaries_sum}
				name="typeOf"
				value={milestone.typeOf}
				onChange={(e) => handelMilestoneChange(e, milestone.id)}
				label="Milestone Type">
				{Object.keys(milestoneText).map((key) => (
					<MenuItem key={key} value={key}>
						{milestoneText[key]}
					</MenuItem>
				))}
			</TextField>
		</div>
		<div className={`flex`}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={6} lg={3}>
					<TextField
						fullWidth type="number"
						name="quantity"
						value={milestone.quantity}
						onChange={(e) => handelMilestoneChange(e, milestone.id)}
						label="Quantity"
						placeholder="Enter quantity" />
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<TextField
						fullWidth type="number"
						name="rate"
						value={milestone.rate}
						onChange={(e) => handelMilestoneChange(e, milestone.id)}
						label="Rate"
						placeholder="Enter rate" />
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<TextField
						fullWidth type="number"
						name="amount"
						value={milestone.amount}
						onChange={(e) => handelMilestoneChange(e, milestone.id)}
						label="Amount"
						placeholder="Enter amount" />
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<DatePicker
						className="w-full"
						label="Duration"
						value={milestone.duration}
						onChange={(e) => handelMilestoneChange(e, milestone.id, "duration")} />
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						multiline
						type="text"
						name="description"
						value={milestone.description}
						onChange={(e) => handelMilestoneChange(e, milestone.id)}
						label="Description"
						placeholder="Type here..." />
				</Grid>
				{
					setImageText &&
					<Grid item xs={12}>
						<input
							accept="image/*"
							className=""
							style={{ display: 'none' }}
							id="file"
							multiple
							type="file"
							onChange={(e) => setImageText(e.target.files.length)} />
						<label htmlFor="file">
							<button
								style={{
									borderStyle: "dashed",
									borderRadius: 0
								}}
								data-testid="login-button"
								type="button"
								onClick={(e) => e.currentTarget.parentElement.click()}
								className="bg-transparent border-2 border-[#007bff]  h-40 my-[3em] text-primary w-full rounded">
								<p className="medium">
									{imageText ? `Uploading ${imageText} Image(s)` : "Upload Images"}
								</p>
							</button>
						</label>
					</Grid>
				}
			</Grid>
		</div>
	</div>;
}